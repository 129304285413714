import {Alert, Dialog, DialogFooter, DialogHeader} from "@material-tailwind/react";
import styles from "./Formulario.module.scss";
import InputComponent from "components/InputComponent";
import InputMask from "react-input-mask";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import EAcesso from "enums/EAcesso";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import {useNavigate} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {UsuariosService} from "services/UsuariosService";
import {ParametrosService} from "services/ParametrosService";
import {IBuscarDadosUsuarioResult} from "interfaces/CommandsResults/UsuariosResults/IBuscarDadosUsuarioResult";
import {ValidarEmailHelper} from "helpers/ValidarEmailHelper";
import {ISalvarAtualizarCommand} from "interfaces/Commands/UsuariosCommands/ISalvarAtualizarCommand";
import ERotas from "enums/ERotas";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import {DataHelper} from "helpers/DataHelper";
import {CepHelper} from "helpers/CepHelper";
import InputDropDragImageComponent from "components/InputDropDragComponents/ImageComponent";
import ModalImage from "react-modal-image";
import SpinnerComponent from "components/SpinnerComponent";
import {DatePicker} from "@mui/x-date-pickers";
import {BaseHelper} from "helpers/BaseHelper";
import PasswordComponent from "../../../../components/PasswordComponent";

interface IProps {
	data?: IBuscarDadosUsuarioResult | undefined;
	textoCriarAtualizar: string;
	novo: boolean;
	isAdmin?: boolean | null;
}

export default function Formulario(props: IProps) {
	const {
		data,
		textoCriarAtualizar,
		novo,
		isAdmin = false
	} = props;

	const navigate = useNavigate();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const token = sessionStorage.getItem("token");
	const acessoLogado = sessionStorage.getItem("acesso");

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(undefined);
	const [nome, setNome] = useState<string>("");
	const [sobrenome, setSobrenome] = useState<string>("");
	const [cpf, setCpf] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [documentoIdentidade, setDocumentoIdentidade] = useState<string>("");
	const [orgaoExpedidor, setOrgaoExpedidor] = useState<string>("");
	const [dataNascimento, setDataNascimento] = useState<Date | null>(null);
	const [genero, setGenero] = useState<string>("");
	const [generoObject, setGeneroObject] = useState<IBuscarComboboxResult | null>(null);
	const [celular, setCelular] = useState<string>("");
	const [whatsapp, setWhatsapp] = useState<string>("");
	const [dataEntrada, setDataEntrada] = useState<Date | null>(null);
	const [cep, setCep] = useState<string>("");
	const [endereco, setEndereco] = useState<string>("");
	const [numero, setNumero] = useState<string>("");
	const [complemento, setComplemento] = useState<string>("");
	const [bairro, setBairro] = useState<string>("");
	const [cidade, setCidade] = useState<string>("");
	const [uf, setUf] = useState<string>("");
	const [ufObject, setUfObject] = useState<IBuscarComboboxResult | null>(null);
	const [tipoVencimento, setTipoVencimento] = useState<string>("");
	const [tipoVencimentoObject, setTipoVencimentoObject] = useState<IBuscarComboboxResult | null>(null);
	const [dataVencimento, setDataVencimento] = useState<Date | null>(null);
	const [acesso, setAcesso] = useState<string>("");
	const [aceitoTermo, setAceitoTermo] = useState<boolean | null>(null);
	const [dataAceitoTermo, setDataAceitoTermo] = useState<string>("");
	const [base64, setBase64] = useState<string>("");
	const [base64Old, setBase64Old] = useState<string>("");
	const [status, setStatus] = useState<number>(1);
	const [statusObject, setStatusObject] = useState<IBuscarComboboxResult | null>(null);

	const [senha, setSenha] = useState<string>("");
	const [visibleSenha, setVisibleSenha] = useState<boolean>(false);

	const [repeteSenha, setRepeteSenha] = useState<string>("");
	const [visibleRepeteSenha, setVisibleRepeteSenha] = useState<boolean>(false);

	const [listaGeneros, setListaGeneros] = useState<IBuscarComboboxResult[]>([]);
	const [listaTipoVencimento, setListaTipoVencimento] = useState<IBuscarComboboxResult[]>([]);
	const [listaEstados, setListaEstados] = useState<IBuscarComboboxResult[]>([]);
	const [listaStatus, setListaStatus] = useState<IBuscarComboboxResult[]>([]);

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleOpen = () => {
		setOpenDialog(!openDialog);
	};

	useEffect(() => {
		popularDados(data);
		buscarCombobox();
	}, [data]);

	useEffect(() => {
		const selectedOption = listaGeneros.find((option) => option.sigla === genero);

		setGeneroObject(selectedOption ?? null);
	}, [genero, listaGeneros]);

	useEffect(() => {
		const selectedOption = listaEstados.find((option) => option.sigla === uf);

		setUfObject(selectedOption ?? null);
	}, [uf, listaEstados]);

	useEffect(() => {
		const selectedOption = listaTipoVencimento.find((option) => option.sigla === tipoVencimento);

		setTipoVencimentoObject(selectedOption ?? null);
	}, [tipoVencimento, listaTipoVencimento]);

	useEffect(() => {
		const selectedOption = listaStatus.find((option) => option.sigla === status.toString());

		setStatusObject(selectedOption ?? null);
	}, [status, listaStatus]);

	function LogToast(tipo: string, mensagem: string) {
		ToastHelper(tipo, mensagem);

		setDesabilitarBotao(false);
		setOpenDialog(false);
	}

	const buscarCombobox = async () => {
		setIsLoading(true);

		try {
			if (!tokenVisao || tokenVisao === "") {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				setIsLoading(false);
				return navigate(ERotas.LOGIN);
			}

			const result = await ParametrosService.buscarCombobox({relatorio: "PERFIL"}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoading(false);
				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoading(false);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoading(false);
				return navigate(-1);
			}

			const data = body.data;

			popularCombobox(data);
			setIsLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	function popularCombobox(dados: IBuscarComboboxResult[]) {
		const generos = dados.filter(item => item.relatorio.includes("GENEROS"));
		const tipoVencimento = dados.filter(item => item.relatorio.includes("TIPO_VENCIMENTO"));
		const estados = dados.filter(item => item.relatorio.includes("ESTADOS"));
		const status = dados.filter(item => item.relatorio.includes("STATUS"));

		setListaGeneros(generos);
		setListaTipoVencimento(tipoVencimento);
		setListaEstados(estados);
		setListaStatus(status);
	}

	function popularDados(data: IBuscarDadosUsuarioResult | undefined) {
		setIsLoading(true);

		if (data != null) {
			setNome(data.nome);
			setSobrenome(data.sobrenome);
			setCpf(data.cpf);
			setEmail(data.email);
			setDocumentoIdentidade(data.documento_identidade);
			setOrgaoExpedidor(data.orgao_expedidor);
			setDataNascimento(DataHelper.converterStringToDate(data.data_nascimento));
			setGenero(data.genero);
			setCelular(data.celular);
			setWhatsapp(data.whatsapp);
			setDataEntrada(DataHelper.converterStringToDate(data.data_entrada));
			setCep(data.cep);
			setEndereco(data.endereco);
			setNumero(data.numero);
			setComplemento(data.complemento);
			setBairro(data.bairro);
			setCidade(data.cidade);
			setUf(data.uf);
			setTipoVencimento(data.tipo_vencimento);
			setDataVencimento(DataHelper.converterStringToDate(data.data_vencimento));
			setAcesso(data.acesso);
			setAceitoTermo(data.aceito_termo);
			setDataAceitoTermo(data.data_aceito_termo);
			setBase64Old(`data:image/png;base64,${data.base64}`);
			setStatus(data.status);

			handleEmailChange(data.email);
		}

		setIsLoading(false);
	}

	const handleEmailChange = (value: string) => {
		setEmail(value);
		setIsValidEmail(ValidarEmailHelper(value));
	};

	const handleCepChange = async (value: string) => {
		setIsLoading(true);

		value = CepHelper.formatarCep(value);

		setCep(value);
		const result = await CepHelper.buscarCep(value);

		const setValuesEmpty = () => {
			setEndereco("");
			setBairro("");
			setCidade("");
			setUf("");
		};

		if (result == null) {
			setValuesEmpty();
			setIsLoading(false);

			return;
		}

		setEndereco(result.logradouro);
		setBairro(result.bairro);
		setCidade(result.localidade);
		setUf(result.uf);

		setIsLoading(false);
	};

	const handleGeneroChange = (newValue: IBuscarComboboxResult | null) => {
		setGeneroObject(newValue);
	};

	const handleUfChange = (newValue: IBuscarComboboxResult | null) => {
		setUfObject(newValue);
	};

	const handleTipoVencimentoChange = (newValue: IBuscarComboboxResult | null) => {
		setTipoVencimentoObject(newValue);
	};

	const handleStatusChange = (newValue: IBuscarComboboxResult | null) => {
		setStatusObject(newValue);
	};

	const handleNavegarPerfil = () => {
		window.open(`/cracha/${props.data?.token}`, "_blank");
	};

	const handleOrgaoExpedidorChange = (value: string) => {
		const orgao = value.trim();

		const regex = /^[^\d]+$/u; // Exclui números usando ^\d (qualquer dígito)

		if (!orgao.match(regex)) {
			ToastHelper("warning", "Não é permitido números no Órgão Expedidor.");

			setOrgaoExpedidor("");
			return;
		}

		setOrgaoExpedidor(value);
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: ISalvarAtualizarCommand = {
				token: props.data?.token ?? "",
				nome: nome,
				sobrenome: sobrenome,
				cpf: cpf,
				email: email,
				senha: senha,
				repete_senha: repeteSenha,
				documento_identidade: documentoIdentidade,
				orgao_expedidor: orgaoExpedidor,
				data_nascimento: DataHelper.getDateTime(dataNascimento, "YYYY-MM-DD"),
				genero: generoObject?.token ?? "",
				celular: celular,
				whatsapp: whatsapp,
				data_entrada: DataHelper.getDateTime(dataEntrada, "YYYY-MM-DD"),
				cep: cep,
				endereco: endereco,
				numero: numero,
				complemento: complemento,
				bairro: bairro,
				cidade: cidade,
				uf: ufObject?.token ?? "",
				tipo_vencimento: tipoVencimentoObject?.token ?? "",
				data_vencimento: DataHelper.getDateTime(dataVencimento, "YYYY-MM-DD"),
				acesso: parseInt(acesso ?? "0"),
				status: statusObject?.token ?? "",
				base64: base64
			};

			const result = await UsuariosService.salvarAtualizar(params, tokenVisao);

			if (!result) {
				return LogToast("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setOpenDialog(false);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				return LogToast("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			const data = body.data;

			LogToast("success", `Usuário ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			if ((data.token ?? "") === tokenVisao) {
				sessionStorage.setItem("nome", params.nome);
				sessionStorage.setItem("sobrenome", params.sobrenome);
				sessionStorage.setItem("token", params.token);
				sessionStorage.setItem("foto", params.base64);
			}

			setTimeout(() => {
				navigate(`${ERotas.USUARIO_EDITAR}/${data.token}`);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				return LogToast("error", error.message);
			}

			return LogToast("error", EStrings.ERRO_NAO_MAPEADO);
		}
	};

	return (
		<form id={"formUsuario"} onSubmit={enviarFormulario} noValidate className={"space-y-3"}>
			{isLoading &&
				<SpinnerComponent/>
			}

			{aceitoTermo &&
				<div className="grid gap-x-6 gap-y-8 sm:grid-cols-12">
					<div className={acessoLogado === EAcesso.MASTER.toString() ? "sm:col-span-11" : "sm:col-span-12"}>
						<Alert color="green">O Usuário aceitou os Termos de Uso no dia {DataHelper.formatDateString(dataAceitoTermo, "DD/MM/YYYY à\\s H:mm")}</Alert>
					</div>
					{acessoLogado === EAcesso.MASTER.toString() &&
						<div className={"sm:col-span-1"}>
							<ButtonDefaultComponent description={"Ver Perfil"} onClick={() => handleNavegarPerfil()}/>
						</div>
					}
				</div>
			}

			{!aceitoTermo && aceitoTermo !== null &&
				<div className="grid gap-x-6 gap-y-8 sm:grid-cols-12">
					<div className={acessoLogado === EAcesso.MASTER.toString() ? "sm:col-span-11" : "sm:col-span-12"}>
						<Alert color="red">O Usuário ainda não aceitou os Termos de Uso.</Alert>
					</div>

					{acessoLogado === EAcesso.MASTER.toString() &&
						<div className={"sm:col-span-1"}>
							<ButtonDefaultComponent description={"Ver Perfil"} onClick={() => handleNavegarPerfil()}/>
						</div>
					}
				</div>
			}

			<div>
				<legend className={"mb-3"}>Upload de Foto de Perfil</legend>

				<div className="grid gap-x-6 gap-y-8 sm:grid-cols-12">
					<div className="sm:col-span-9">
						<InputDropDragImageComponent setBase64={setBase64} accept={"image/png"}/>
					</div>

					<div className="sm:col-span-3">
						<ModalImage small={base64 === "" ? base64Old : base64} large={base64 === "" ? base64Old : base64} alt={"Foto de Perfil"} hideZoom={false} className={styles.img}/>
					</div>
				</div>

				<legend className={"mt-4 mb-3"}>Dados Pessoais</legend>

				<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-3">
					<div className="sm:col-span-5">
						<InputComponent
							type={"text"}
							label={"Nome"}
							value={nome}
							validation={nome != ""}
							required={true}
							readonly={novo ? false : (!isAdmin ?? false)}
							onChange={(event) => setNome(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-4">
						<InputComponent
							type={"text"}
							label={"Sobrenome"}
							value={sobrenome}
							validation={sobrenome != ""}
							required={true}
							readonly={novo ? false : (!isAdmin ?? false)}
							onChange={(event) => setSobrenome(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-3">
						<DatePicker
							label="Data de Nascimento"
							format={"dd/MM/yyyy"}
							value={dataNascimento}
							onChange={(e) => setDataNascimento(e)}
							maxDate={new Date()}
							yearsPerRow={3}
							disableFuture={true}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small"
								}
							}}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<InputMask mask={"999.999.999-99"} maskPlaceholder={null} value={cpf} onChange={(event) => setCpf(event.target.value)}>
							<InputComponent
								type={"text"}
								label={"CPF"}
								value={cpf}
								validation={cpf != ""}
								required={true}
								readonly={novo ? false : (!isAdmin ?? false)}
							/>
						</InputMask>
					</div>

					<div className={"sm:col-span-3"}>
						<Autocomplete
							color={genero != "" ? "success" : "info"}
							disablePortal
							options={listaGeneros}
							getOptionLabel={(option) => option.descricao}
							value={generoObject}
							size={"small"}
							renderInput={(params) => <TextField {...params} label="Gêneros"/>}
							onChange={(_, newValue) => handleGeneroChange(newValue)}
							disabled={novo ? false : (!isAdmin ?? false)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<InputComponent
							type={"text"}
							label={"Documento de Identificação"}
							value={documentoIdentidade}
							validation={documentoIdentidade != ""}
							required={true}
							readonly={novo ? false : (!isAdmin ?? false)}
							onChange={(event) => setDocumentoIdentidade(event.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<InputComponent
							type={"text"}
							label={"Órgão Expedidor"}
							value={orgaoExpedidor}
							validation={orgaoExpedidor != ""}
							required={true}
							readonly={novo ? false : (!isAdmin ?? false)}
							onChange={(event) => setOrgaoExpedidor(event.target.value)}
							onBlur={(e) => handleOrgaoExpedidorChange(e.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<InputMask mask={"(99) 9999-9999"} maskPlaceholder={null} value={celular} onChange={(event) => setCelular(event.target.value)}>
							<InputComponent
								type={"text"}
								label={"Celular"}
								value={celular}
								validation={celular != ""}
								required={true}
							/>
						</InputMask>
					</div>

					<div className={"sm:col-span-3"}>
						<InputMask mask={"(99) 99999-9999"} maskPlaceholder={null} value={whatsapp} onChange={(event) => setWhatsapp(event.target.value)}>
							<InputComponent
								type={"text"}
								label={"Whatsapp"}
								value={whatsapp}
								validation={whatsapp != ""}
								required={true}
							/>
						</InputMask>
					</div>

					<div className={"sm:col-span-3"}>
						<InputComponent
							type={"email"}
							label={"E-mail"}
							value={email}
							validation={isValidEmail}
							required={true}
							readonly={novo ? false : (!isAdmin ?? false)}
							onChange={(event) => handleEmailChange(event.target.value)}
						/>
					</div>

					<div className={"sm:col-span-3"}>
						<DatePicker
							label={"Celebrante desde"}
							format={"dd/MM/yyyy"}
							value={dataEntrada}
							onChange={(e) => setDataEntrada(e)}
							yearsPerRow={3}
							disabled={novo ? false : (!isAdmin ?? false)}
							slotProps={{
								textField: {
									fullWidth: true,
									required: true,
									size: "small"
								}
							}}
						/>
					</div>
				</div>

				<legend className={"mt-4 mb-3"}>Endereço do Usuário</legend>

				<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-3">
					<div className="sm:col-span-3">
						<InputComponent
							type={"text"}
							label={"CEP"}
							value={cep}
							validation={cep != ""}
							required={true}
							onChange={(e) => setCep(e.target.value)}
							onBlur={(e) => handleCepChange(e.target.value)}
						/>
					</div>

					<div className="sm:col-span-7">
						<InputComponent
							type={"text"}
							label={"Endereço"}
							value={endereco}
							validation={endereco != ""}
							required={true}
							readonly={true}
							onChange={(event) => setEndereco(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-2">
						<InputComponent
							type={"text"}
							label={"Número"}
							value={numero}
							validation={numero != ""}
							required={true}
							onChange={(event) => setNumero(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-3">
						<InputComponent
							type={"text"}
							label={"Complemento"}
							value={complemento}
							required={false}
							onChange={(event) => setComplemento(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-3">
						<InputComponent
							type={"text"}
							label={"Bairro"}
							value={bairro}
							validation={bairro != ""}
							required={true}
							readonly={true}
							onChange={(event) => setBairro(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-3">
						<InputComponent
							type={"text"}
							label={"Cidade"}
							value={cidade}
							validation={cidade != ""}
							required={true}
							readonly={true}
							onChange={(event) => setCidade(event.target.value)}
						/>
					</div>

					<div className="sm:col-span-3">
						<Autocomplete
							color={uf != "" ? "success" : "info"}
							options={listaEstados}
							getOptionLabel={(option) => option.descricao}
							value={ufObject}
							size={"small"}
							renderInput={(params) => <TextField {...params} label="UF"/>}
							onChange={(_, newValue) => handleUfChange(newValue)}
						/>
					</div>
				</div>

				{sessionStorage.getItem("acesso") == EAcesso.MASTER.toString() &&
					<>
						<legend className={"mt-4 mb-3"}>Administração do Usuário</legend>

						<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-3">
							<div className="sm:col-span-4">
								<Autocomplete
									color={tipoVencimento != "" ? "success" : "info"}
									disablePortal
									options={listaTipoVencimento}
									getOptionLabel={(option) => option.descricao}
									value={tipoVencimentoObject}
									fullWidth={true}
									size={"small"}
									renderInput={(params) => <TextField {...params} label="Tipo de Vencimento"/>}
									onChange={(_, newValue) => handleTipoVencimentoChange(newValue)}
								/>
							</div>

							<div className="sm:col-span-4">
								<DatePicker
									label={"Data de Vencimento"}
									format={"dd/MM/yyyy"}
									value={dataVencimento}
									onChange={(e) => setDataVencimento(e)}
									yearsPerRow={3}
									slotProps={{
										textField: {
											fullWidth: true,
											required: true,
											size: "small"
										}
									}}
								/>
							</div>

							<div className={"sm:col-span-4"}>
								<Autocomplete
									disablePortal
									options={listaStatus}
									getOptionLabel={(option) => option.descricao}
									value={statusObject}
									fullWidth={true}
									size={"small"}
									renderInput={(params) => <TextField {...params} label={"Status"}/>}
									onChange={(_, newValue) => handleStatusChange(newValue)}
								/>
							</div>
						</div>
					</>
				}

				{token === data?.token &&
					<>
						<legend className={"mt-4 mb-3"}>Dados de Acesso</legend>

						<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-3">
							<div className="sm:col-span-6">
								<PasswordComponent
									label={"Senha"}
									onChange={(event) => setSenha(event.target.value)}
									visible={visibleSenha}
									setVisible={setVisibleSenha}
									required={true}
								/>
							</div>

							<div className="sm:col-span-6">
								<PasswordComponent
									label={"Repita sua senha"}
									onChange={(event) => setRepeteSenha(event.target.value)}
									visible={visibleRepeteSenha}
									setVisible={setVisibleRepeteSenha}
									required={true}
								/>
							</div>
						</div>
					</>
				}
			</div>

			<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-6 mb-1">
				<div className="sm:col-span-12 ml-auto mr-auto">
					<ButtonDefaultComponent description={textoCriarAtualizar} onClick={handleOpen}/>
					<Dialog open={openDialog} handler={handleOpen} size={"sm"} animate={BaseHelper.AnimationDialog}>
						<DialogHeader>Tem certeza que deseja {novo ? "criar" : "atualizar"} o usuário?</DialogHeader>
						<DialogFooter className={"space-x-2"}>
							<ButtonDefaultComponent color={"red"} description={"Cancelar"} onClick={handleOpen} desabilitar={desabilitarBotao}/>
							<ButtonSubmitComponent form={"formUsuario"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
						</DialogFooter>
					</Dialog>
				</div>
			</div>
		</form>
	);
}