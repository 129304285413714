import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import {GridColDef} from "@mui/x-data-grid";
import {DataHelper} from "helpers/DataHelper";
import {Button, Card, CardBody, Menu, MenuHandler, MenuList, Typography} from "@material-tailwind/react";
import TableComponent from "components/TableComponent";
import EAcesso from "enums/EAcesso";
import EStatusRequerimento from "enums/EStatusRequerimento";
import {HiOutlineArrowUturnLeft, HiOutlineChevronUpDown, HiOutlineDocumentCheck, HiOutlineExclamationCircle, HiOutlinePencilSquare, HiOutlineStar, HiOutlineTrash} from "react-icons/hi2";
import {useNavigate} from "react-router-dom";
import ERotas from "enums/ERotas";
import {HiOutlineDownload, HiOutlineExclamation, HiOutlinePencilAlt} from "react-icons/hi";
import {IconButton} from "@mui/material";
import {IDownloadRequerimentoAprovadoCommand} from "interfaces/Commands/RequerimentosCommands/IDownloadRequerimentoAprovadoCommand";
import {RequerimentosService} from "services/RequerimentosService";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import React, {useState} from "react";
import {ToastHelper} from "helpers/ToastHelper";
import ModalRequerimentoReprovado from "../modal/reprovado";
import ModalRequerimentoVisualizarComprovante from "../modal/visualizarComprovante";
import ModalRequerimentoSolicitarCancelamento from "../modal/solicitarCancelamento";
import ModalRequerimentoVisualizarCancelamento from "../modal/visualizarCancelamento";
import ModalRequerimentoRollback from "../modal/rollback";
import {FileHelper} from "helpers/FileHelper";
import {TimelineRequerimentoComponent} from "../timelineComponent";

interface IProps {
	data: IListarRequerimentoResult[];
	setIsLoadingIndex: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TabelaRequerimento(props: IProps) {
	const navigate = useNavigate();

	const {data, setIsLoadingIndex, setAtualizarLista} = props;

	const acesso = sessionStorage.getItem("acesso");
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const token = sessionStorage.getItem("token");

	const [requerimento, setRequerimento] = useState<IListarRequerimentoResult>();
	const [isOpenModalReprovado, setIsOpenModalReprovado] = useState<boolean>(false);
	const [isOpenModalVisualizarComprovante, setIsOpenModalVisualizarComprovante] = useState<boolean>(false);
	const [isOpenModalSolicitarCancelamento, setIsOpenModalSolicitarCancelamento] = useState<boolean>(false);
	const [isOpenModalVisualizarCancelamento, setIsOpenModalVisualizarCancelamento] = useState<boolean>(false);
	const [isOpenModalRollback, setIsOpenModalRollback] = useState<boolean>(false);
	const [openTimeline, setOpenTimeline] = useState<boolean>(false);

	const handleDownload = async (tokenRequerimento: string) => {
		setIsLoadingIndex(true);
		const params: IDownloadRequerimentoAprovadoCommand = {
			token: tokenRequerimento
		};

		try {
			const result = await RequerimentosService.downloadAprovado(params, tokenVisao ?? "");

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			ToastHelper("success", EStrings.DOWNLOAD_SUCESSO);
			FileHelper.download(body.data.base64, "requerimento_aprovado.zip");
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		} finally {
			setIsLoadingIndex(false);
		}
	};

	const openModalReprovado = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setIsOpenModalReprovado(true);
	};

	const openModalVisualizarComprovante = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setIsOpenModalVisualizarComprovante(true);
	};

	const openModalSolicitarCancelamento = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setIsOpenModalSolicitarCancelamento(true);
	};

	const openModalVisualizarCancelamento = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setIsOpenModalVisualizarCancelamento(true);
	};

	const openModalRollback = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setIsOpenModalRollback(true);
	};

	const handleOpenTimeline = (requerimento: IListarRequerimentoResult) => {
		setRequerimento(requerimento);
		fecharTodosModais();
		setOpenTimeline(true);
	};

	const fecharTodosModais = () => {
		setIsOpenModalReprovado(false);
		setIsOpenModalVisualizarComprovante(false);
		setIsOpenModalSolicitarCancelamento(false);
		setIsOpenModalVisualizarCancelamento(false);
		setIsOpenModalRollback(false);
	};

	const columns: GridColDef[] = [
		{
			field: "id", headerName: "ID", width: 50, renderCell: (params) => {
				return `#${params.row.id}`;
			}
		},
		{
			field: "prazo", headerName: "Prazo", width: 130, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value, "DD/MM/YY HH:mm");
			}
		},
		{
			field: "data_evento", headerName: "Data", width: 80, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value, "DD/MM/YY");
			}
		}
	];

	if (acesso === EAcesso.CELEBRANTE.toString()) {
		columns.push(
			{
				field: "hora_evento", headerName: "Hora", width: 100, valueGetter: (params) => {
					return DataHelper.formatHourString(params.value, "HH:mm");
				}
			}
		);
	}

	if (acesso == EAcesso.MASTER.toString()) {
		columns.push(
			{field: "nome_solicitante", headerName: "Solicitante", width: 200, cellClassName: "multiline-cell"},
		);
	}

	columns.push(
		{
			field: "nubentes", headerName: "Nubentes", minWidth: 250, flex: 100, cellClassName: "multiline-cell", valueGetter: (params) => {
				return `${params.row.nome_primeiro_nubente} - ${params.row.nome_segundo_nubente}`;
			}
		},
		{field: "descricao_status", headerName: "Status", width: 120, cellClassName: "multiline-cell"},
		{
			field: "acoes", headerName: "Ações", headerAlign: "center", width: 75, align: "center", renderCell: (params) => {
				const handleRedirect = (rota: string) => {
					navigate(rota);
				};

				return (
					<>
						{params.row.status != EStatusRequerimento.CANCELADO &&
							<Menu>
								<MenuHandler>
									<IconButton>
										<HiOutlineChevronUpDown/>
									</IconButton>
								</MenuHandler>
								<MenuList className={"space-y-2"}>
									{(params.row.status == EStatusRequerimento.PENDENTE || params.row.status == EStatusRequerimento.AGUARDANDO) && acesso == EAcesso.CELEBRANTE.toString() && params.row.token_solicitante == token &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"amber"} onClick={() => handleRedirect(`${ERotas.REQUERIMENTO_EDITAR}/${params.row.token}`)}>
											<HiOutlinePencilSquare className={"w-4 h-4"}/>
											<Typography variant="small" className="font-normal">
												Editar
											</Typography>
										</Button>
									}

									{(params.row.status == EStatusRequerimento.APROVADO || params.row.status == EStatusRequerimento.FINALIZADO) &&
										<>
											<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"blue"} onClick={() => handleDownload(params.row.token)}>
												<HiOutlineDownload className={"w-4 h-4"}/>
												<Typography variant="small" className="font-normal">
													Baixar
												</Typography>
											</Button>

											{params.row.token_solicitante == token && acesso == EAcesso.CELEBRANTE.toString() && params.row.id_termo == null &&
												<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"green"} onClick={() => handleRedirect(`${ERotas.REQUERIMENTO_SOLICITAR_EDICAO}/${params.row.token}`)}>
													<HiOutlinePencilAlt className={"w-4 h-4"}/>
													<Typography variant="small" className="font-normal">
														Edição
													</Typography>
												</Button>
											}
										</>
									}

									{params.row.status == EStatusRequerimento.PENDENTE && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"green"} onClick={() => handleRedirect(`${ERotas.REQUERIMENTO_VISUALIZAR}/${params.row.token}`)}>
											<HiOutlineDocumentCheck className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Visualizar
											</Typography>
										</Button>
									}

									{params.row.status == EStatusRequerimento.REPROVADO &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"cyan"} onClick={() => openModalReprovado(params.row)}>
											<HiOutlineExclamation className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Reprovado
											</Typography>
										</Button>
									}

									{params.row.status == EStatusRequerimento.EDICAO && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"cyan"} onClick={() => openModalVisualizarComprovante(params.row)}>
											<HiOutlineExclamationCircle className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Visualizar Comprovante
											</Typography>
										</Button>
									}

									{params.row.id_termo == null && token === params.row.token_solicitante && acesso === EAcesso.CELEBRANTE.toString() && (params.row.status !== EStatusRequerimento.CANCELADO && params.row.status !== EStatusRequerimento.SOLICITACAO_CANCELAMENTO) &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"red"} onClick={() => openModalSolicitarCancelamento(params.row)}>
											<HiOutlineTrash className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Cancelar
											</Typography>
										</Button>
									}

									{params.row.status == EStatusRequerimento.SOLICITACAO_CANCELAMENTO && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"red"} onClick={() => openModalVisualizarCancelamento(params.row)}>
											<HiOutlineTrash className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Cancelar
											</Typography>
										</Button>
									}

									{(params.row.status == EStatusRequerimento.APROVADO || params.row.status == EStatusRequerimento.REPROVADO) && params.row.id_termo == null && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center} flex justify-between"} size={"sm"} variant={"gradient"} color={"teal"} onClick={() => openModalRollback(params.row)}>
											<HiOutlineArrowUturnLeft className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Rollback
											</Typography>
										</Button>
									}

									{params.row.token &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"white"} onClick={() => handleOpenTimeline(params.row)}>
											<HiOutlineStar className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Timeline
											</Typography>
										</Button>
									}
								</MenuList>
							</Menu>
						}
					</>
				);
			}
		}
	);

	return (
		<>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<TableComponent columns={columns} rows={data}/>
					<ModalRequerimentoReprovado requerimento={requerimento} open={isOpenModalReprovado} setOpen={setIsOpenModalReprovado}/>
					<ModalRequerimentoVisualizarComprovante requerimento={requerimento} open={isOpenModalVisualizarComprovante} setOpen={setIsOpenModalVisualizarComprovante} setAtualizarLista={setAtualizarLista}/>
					<ModalRequerimentoSolicitarCancelamento requerimento={requerimento} open={isOpenModalSolicitarCancelamento} setOpen={setIsOpenModalSolicitarCancelamento} setAtualizarLista={setAtualizarLista}/>
					<ModalRequerimentoVisualizarCancelamento requerimento={requerimento} open={isOpenModalVisualizarCancelamento} setOpen={setIsOpenModalVisualizarCancelamento} setAtualizarLista={setAtualizarLista}/>
					<ModalRequerimentoRollback requerimento={requerimento} open={isOpenModalRollback} setOpen={setIsOpenModalRollback} setAtualizarLista={setAtualizarLista}/>
				</CardBody>
			</Card>
			<TimelineRequerimentoComponent
				requerimento={requerimento?.token ?? ""}
				nomePrimeiroNubente={requerimento?.nome_primeiro_nubente ?? ""}
				nomeSegundoNubente={requerimento?.nome_segundo_nubente ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
}