import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import FormularioNovoEditar from "../_components/formularios/novoEditar";
import EAcesso from "enums/EAcesso";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {RequerimentosService} from "services/RequerimentosService";
import { ToastHelper } from "helpers/ToastHelper";

export default function AdicionarRequerimento() {
	const navigate = useNavigate();
	const acesso = sessionStorage.getItem("acesso");
	const tokenVisao = sessionStorage.getItem("tokenLogado");

	useEffect(() => {
		verificaCriacao();
	});

	useEffect(() => {
		if (acesso != EAcesso.CELEBRANTE.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const verificaCriacao = async () => {
		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const result = await RequerimentosService.validarCriacao(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (!body.data.valido) {
				ToastHelper("warning", EStrings.REQUERIMENTO_NAO_AUTENTICADO_ACAO);

				return navigate(-1);
			}
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	return (
		<>
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Requerimentos </a>
					<a className="opacity-60"> Adicionar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<FormularioNovoEditar textoCriarAtualizar={"Criar Requerimento"} novo={true}/>
				</CardBody>
			</Card>
		</>
	);
}