import {IListarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IListarRequerimentoResult";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogBody, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "../../../../../components/Buttons/DefaultComponent";
import ERotas from "../../../../../enums/ERotas";
import { BaseHelper } from "helpers/BaseHelper";

interface IProps {
	requerimento?: IListarRequerimentoResult;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalRequerimentoReprovado(props: IProps) {
	const {
		requerimento,
		open,
		setOpen
	} = props;

	const navigate = useNavigate();
	const token = sessionStorage.getItem("token");

	const handleRedirect = (rota: string) => {
		navigate(rota);
	};

	return (
		<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
			<DialogHeader>Requerimento Reprovado</DialogHeader>
			<DialogBody>
				<Typography variant={"small"} className={"font-monospace"}>
					{requerimento?.motivo_reprovacao ?? ""}
				</Typography>
			</DialogBody>
			<DialogFooter className={"space-x-2"}>
				<ButtonDefaultComponent color={"red"} description={"Fechar"} onClick={() => setOpen(false)}/>
				{requerimento?.token_solicitante === token &&
					<ButtonDefaultComponent color={"amber"} description={"Corrigir"} onClick={() => handleRedirect(`${ERotas.REQUERIMENTO_SOLICITAR_EDICAO}/${requerimento?.token}`)}/>
				}
			</DialogFooter>
		</Dialog>
	);
}