import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import SpinnerComponent from "components/SpinnerComponent";
import {Alert, Breadcrumbs, Card, CardBody, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {TermosService} from "services/TermosService";
import TabelaTermo from "../../_components/tabelas/listarTermos";
import {IListarTermosCommand} from "interfaces/Commands/TermosCommands/IListarTermosCommand";
import {EStatusTermo} from "enums/EStatusTermo";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import {ToastHelper} from "helpers/ToastHelper";
import {HiOutlineExclamation} from "react-icons/hi";
import EAcesso from "enums/EAcesso";

export default function ListarTermosAprovados() {
	const navigate = useNavigate();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const acesso = sessionStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [lista, setLista] = useState<IListarTermoResult[]>([]);
	const [atualizarLista, setAtualizarLista] = useState<boolean>(false);

	useEffect(() => {
		listarTermos();
	}, []);

	useEffect(() => {
		listarTermos();
	}, [atualizarLista]);

	const listarTermos = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IListarTermosCommand = {
				status: [EStatusTermo.APROVADO, EStatusTermo.SOLICITACAO_FINALIZACAO]
			};

			const result = await TermosService.listar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setLista(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<>
				<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
					<Breadcrumbs>
						<a className="opacity-60"> Termos </a>
						<a className="opacity-60"> Aprovados </a>
					</Breadcrumbs>
					<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
				</div>
				<Card className={"w-100"}>
					<CardBody className={"flex flex-col gap-4"}>
						{acesso == EAcesso.CELEBRANTE.toString() &&
							<Card className={"w-full"}>
								<CardBody className={"w-full m-0 p-0 space-y-2"}>
									<Alert color={"red"} className={"w-full space-x-2 text-center flex items-center justify-center"} variant={"gradient"} icon={<HiOutlineExclamation/>}>
										<Typography className="font-medium">
											Orientação Importante!
										</Typography>
									</Alert>
									<div className="card-body row text-justify p-5 space-y-2">
										<Typography variant={"h6"} className={"font-monospace"}>Porque favor, siga as instruções abaixo:</Typography>
										<ul className={"mt-2 ml-2 list-inside list-disc"}>
											<li>Imprima o TERMO DA ICRD em duas vias, em CORES, e peça para todos os envolvidos assinarem por extenso, sem abreviaturas.</li>
											<li>Uma via do TERMO DEVE SER DEVOLVIDA ao cartório que emitiu a habilitação, com a sua assinatura reconhecida. Não reconheça a sua assinatura antes do casamento.</li>
											<li>Se você não tiver firma aberta no cartório que emitiu a habilitação, reconheça a sua assinatura onde tem firma aberta e entregue o TERMO aos noivos, para que eles levem ao cartório para que a CERTIDÃO DE CASAMENTO seja emitida. Fique atento(a) ao prazo de 90 dias, conforme informado na habilitação.</li>
											<li>ARQUIVE a Habilitação de Casamento e o Termo na sua pasta (seu livro ATA).</li>
											<li>Finalize o TERMO assinado na Plataforma no 1° dia útil após a cerimônia.</li>
										</ul>
									</div>
								</CardBody>
							</Card>
						}

						<TabelaTermo data={lista} setIsLoadingIndex={setIsLoadingIndex} setAtualizarLista={setAtualizarLista}/>
					</CardBody>
				</Card>
			</>
		</>
	);
}