import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

/* COMPONENTS */
import TableComponent from "components/TableComponent";
import {GridColDef} from "@mui/x-data-grid";

/* HELPERS E SERVICES */
import {ToastHelper} from "helpers/ToastHelper";
import {UsuariosService} from "services/UsuariosService";

/* INTERFACES, COMMANDS E ENUMS */
import EStrings from "enums/EStrings";
import {IListarResult} from "interfaces/CommandsResults/UsuariosResults/IListarResult";
import SpinnerComponent from "components/SpinnerComponent";
import {Breadcrumbs, Button, Card, CardBody} from "@material-tailwind/react";
import ERotas from "enums/ERotas";
import {HiOutlinePencilSquare} from "react-icons/hi2";
import {DataHelper} from "helpers/DataHelper";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EAcesso from "enums/EAcesso";

export default function ListarUsuarios() {
	const navigate = useNavigate();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const token = sessionStorage.getItem("token");
	const acesso = sessionStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [listaUsuarios, setListaUsuarios] = useState<IListarResult[]>([]);

	useEffect(() => {
		listarUsuarios();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			navigate(-1);
			return;
		}
	}, [acesso]);

	const listarUsuarios = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const result = await UsuariosService.listar(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setListaUsuarios(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	const columns: GridColDef[] = [
		{field: "id", headerName: "ID", headerAlign: "center", width: 70, align: "center"},
		{
			field: "data_entrada", headerName: "Data de Entrada", headerAlign: "center", width: 150, align: "center", valueGetter: (params) => {
				return DataHelper.formatDateString(params.value);
			}
		},
		{field: "nome", headerName: "Nome", flex: 100},
		{field: "whatsapp", headerName: "Whatsapp", width: 150},
		{field: "email", headerName: "E-mail", width: 250},
		{
			field: "data_vencimento", headerName: "Data de Vencimento", headerAlign: "center", width: 150, align: "center", valueGetter: (params) => {
				return DataHelper.formatDateString(params.value);
			}
		},
		{field: "status", headerName: "Status", headerAlign: "center", width: 100, align: "center"},
		{
			field: "acoes", headerName: "Ações", headerAlign: "center", width: 100, align: "center", renderCell: (params) => {
				const isUser = token == params.row.token ?? false;

				const handleRedirect = () => {
					const token = params.row.token;

					navigate(`${ERotas.USUARIO_EDITAR}/${token}`);
				};

				return (
					<>
						{!isUser &&
							(
								<Button
									className={"rounded-full"}
									size="sm"
									variant="gradient"
									color={"amber"}
									onClick={handleRedirect}
								>
									<HiOutlinePencilSquare/>
								</Button>
							)
						}
					</>
				);
			}
		}
	];

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a className="opacity-60"> Usuários </a>
					<a className="opacity-60"> Listar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<div>
						<ButtonDefaultComponent description={"Adicionar"} onClick={() => navigate(ERotas.USUARIO_ADICIONAR)}/>
					</div>
					<TableComponent columns={columns} rows={listaUsuarios}/>
				</CardBody>
			</Card>
		</>
	);
}