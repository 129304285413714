import {ToastHelper} from "./ToastHelper";
import Cookies from "js-cookie";
import EStrings from "enums/EStrings";

const redirectToLogin = (type: string, message: string) => {
	ToastHelper(type, message);
	sessionStorage.clear();
	Cookies.remove(EStrings.COOKIE);

	return;
};

export const LogErrorHelper = {
	redirectToLogin
};