import {FormEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

/* ESTILOS E ASSETS*/
import styles from "./Login.module.scss";

/* COMPONENTS */
import InputComponent from "components/InputComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";

/* HELPERS E SERVICES */
import {ValidarEmailHelper} from "helpers/ValidarEmailHelper";
import {ToastHelper} from "helpers/ToastHelper";

/* INTERFACES, COMMANDS E ENUMS */
import {UsuariosService} from "services/UsuariosService";
import {ILoginCommand} from "interfaces/Commands/UsuariosCommands/ILoginCommand";
import EDeletado from "enums/EDeletado";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {Checkbox} from "@material-tailwind/react";
import {IBuscarTokenizacaoCommand} from "../../interfaces/Commands/UsuariosCommands/IBuscarTokenizacaoCommand";
import PasswordComponent from "../../components/PasswordComponent";

export default function Login() {
	const navigate = useNavigate();

	const [email, setEmailState] = useState<string>("");
	const [senha, setSenha] = useState<string>("");
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false);
	const [conectado, setConectado] = useState<boolean>(false);
	const [isValidEmail, setIsValidEmail] = useState<boolean | undefined>(undefined);
	const [isValidSenha, setIsValidSenha] = useState<boolean | undefined>(undefined);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	useEffect(() => {
		const localToken = Cookies.get(EStrings.COOKIE);

		if (localToken !== undefined) {
			buscarTokenizacao(localToken);
		}
	}, [0]);

	const handleEmailChange = (value: string) => {
		setEmailState(value);
		setIsValidEmail(ValidarEmailHelper(value));

		setDesabilitarBotao(!isValidEmail && !isValidSenha);
	};

	const handleSenhaChange = (value: string) => {
		setSenha(value);
		setIsValidSenha(value != "");

		setDesabilitarBotao(!isValidEmail && !isValidSenha);
	};

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setDesabilitarBotao(true);

		try {
			const params: ILoginCommand = {
				email: email,
				senha: senha,
				conectado: conectado
			};

			if (params.email == null || params.email == "") {
				ToastHelper("warning", EStrings.EMAIL_OBRIGATORIO);

				return;
			}

			if (params.senha == null || params.senha == "") {
				ToastHelper("warning", EStrings.SENHA_OBRIGATORIO);

				return;
			}

			const result = await UsuariosService.login(params);

			if (!result) {
				ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			const data = body.data;

			if (data.d_e_l_e_t_ === EDeletado.INATIVO) {
				ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO);

				return;
			}

			sessionStorage.setItem("tokenLogado", data.token);
			sessionStorage.setItem("email", data.email);
			sessionStorage.setItem("acesso", data.acesso.toString());
			sessionStorage.setItem("tentativa", "0");

			if (conectado) {
				Cookies.set(EStrings.COOKIE, data.token, {expires: 7});
			}

			navigate(ERotas.PRINCIPAL);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		} finally {
			setDesabilitarBotao(false);
		}
	};

	const handleAcessarPagina = (rota: string) => {
		navigate(rota);
	};

	const buscarTokenizacao = async (token: string) => {
		try {
			const params: IBuscarTokenizacaoCommand = {
				token: token
			};

			const result = await UsuariosService.buscarTokenizacao(params);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const body = result.data;

			if (body == null) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			const data = body.data;

			if (data.d_e_l_e_t_ === EDeletado.INATIVO) {
				ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO);

				Cookies.remove(EStrings.COOKIE);
				return;
			}

			sessionStorage.setItem("tokenLogado", data.token);
			sessionStorage.setItem("email", data.email);
			sessionStorage.setItem("acesso", data.acesso.toString());
			sessionStorage.setItem("tentativa", "0");

			navigate(ERotas.PRINCIPAL);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		}
	};

	return (
		<>
			<form className={styles.form} id={"formLogin"} onSubmit={enviarFormulario} noValidate>
				<div className={"grid w-full sm:grid-cols-12 mt-4"}>
					<div className={"sm:col-span-12 mb-4"}>
						<InputComponent
							type={"email"}
							label={"E-mail"}
							onChange={event => handleEmailChange(event.target.value)}
							validation={isValidEmail != undefined ? isValidEmail : false}
							required={true}
							lowercase={true}
						/>
					</div>

					<div className={"sm:col-span-12 mb-4"}>
						<PasswordComponent
							label={"Senha"}
							onChange={event => handleSenhaChange(event.target.value)}
							visible={visiblePassword}
							setVisible={setVisiblePassword}
							required={true}
						/>
					</div>

					<div className={"sm:col-span-12 mb-4 mr-auto"}>
						<Checkbox crossOrigin={undefined} label={"Mantenha conectado"} color={"amber"} onChange={(e) => setConectado(e.target.checked)} className={"h-4 w-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0 ml-auto"}/>
					</div>

					<div className={"sm:col-span-12 mb-4"}>
						<ButtonSubmitComponent form={"formLogin"} fullWidth={true} desabilitar={desabilitarBotao} description={"Acessar"}/>
					</div>
				</div>

				<hr className={"mb-4"}/>

				<div className={"grid w-full sm:grid-cols-12"}>
					<div className={"sm:col-span-6 text-center"}>
						<ButtonDefaultComponent variant={"text"} description={"Esqueci minha senha"} onClick={() => handleAcessarPagina(ERotas.ESQUECI_SENHA)}/>
					</div>
					<div className={"sm:col-span-6 text-center"}>
						<ButtonDefaultComponent variant={"text"} description={"Primeiro Acesso"} onClick={() => handleAcessarPagina(ERotas.PRIMEIRO_ACESSO)}/>
					</div>
				</div>
			</form>
		</>
	);
}
