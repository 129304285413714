import {useNavigate, useParams} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import {IBuscarRequerimentoResult} from "interfaces/CommandsResults/RequerimentosResults/IBuscarRequerimentoResult";
import EAcesso from "enums/EAcesso";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {IBuscarRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IBuscarRequerimentoCommand";
import {RequerimentosService} from "services/RequerimentosService";
import SpinnerComponent from "components/SpinnerComponent";
import {Breadcrumbs, Button, ButtonGroup, Card, CardBody, Dialog, DialogBody, DialogFooter, DialogHeader, IconButton, List, ListItem, ListItemSuffix, Textarea, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import FormularioNovoEditar from "../_components/formularios/novoEditar";
import {HiOutlineArrowDownTray, HiOutlineArrowTopRightOnSquare, HiOutlineTrash} from "react-icons/hi2";
import {IBuscarPdfRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IBuscarPdfRequerimentoCommand";
import {PdfHelper} from "helpers/PdfHelper";
import {ToastHelper} from "helpers/ToastHelper";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import InputDropDragPdfComponent from "components/InputDropDragComponents/PdfComponent";
import {IReprovarRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IReprovarRequerimentoCommand";
import EStatusRequerimento from "enums/EStatusRequerimento";
import {IAprovarRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IAprovarRequerimentoCommand";
import {BaseHelper} from "helpers/BaseHelper";
import {TimelineRequerimentoComponent} from "../_components/timelineComponent";
import {IBuscarModeloPdfRequerimentoCommand} from "interfaces/Commands/RequerimentosCommands/IBuscarModeloPdfRequerimentoCommand";

export default function VisualizarRequerimento() {
	const navigate = useNavigate();
	const {token} = useParams();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const acesso = sessionStorage.getItem("acesso");

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
	const [data, setData] = useState<IBuscarRequerimentoResult>();

	const [openTimeline, setOpenTimeline] = useState<boolean>(false);
	const [openDialogAprova, setOpenDialogAprova] = useState(false);
	const [openDialogReprova, setOpenDialogReprova] = useState(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [motivoReprovacao, setMotivoReprovacao] = useState<string>("");

	const [files, setFiles] = useState<File[]>([]);

	useEffect(() => {
		buscarDados();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const buscarDados = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		if (!token || token === "") {
			ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(-1);
		}

		try {
			setIsLoadingIndex(false);

			const params: IBuscarRequerimentoCommand = {
				token: token
			};

			const result = await RequerimentosService.buscar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (body.data.status !== EStatusRequerimento.PENDENTE) {
				ToastHelper("warning", EStrings.REQUERIMENTO_NAO_AUTENTICADO_ACAO);

				return navigate(-1);
			}

			setData(body.data);
		} catch (error) {
			setIsLoadingIndex(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const handlePdfChange = async () => {
		setIsLoading(true);

		const params: IBuscarPdfRequerimentoCommand = {
			token: token ?? ""
		};

		try {
			const result = await RequerimentosService.buscarPdf(params, tokenVisao ?? "");

			if (!result) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoading(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setIsLoading(false);
			PdfHelper.showPdf(body.data.base64);

			ToastHelper("success", "PDF gerado com sucesso.");
		} catch (error) {
			setIsLoading(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const handleOpenDialogAprova = () => {
		setFiles([]);
		setOpenDialogAprova(!openDialogAprova);
	};

	const handleOpenDialogReprova = () => {
		setMotivoReprovacao("");
		setOpenDialogReprova(!openDialogReprova);
	};

	const handleExcluirFile = (indexToRemove: number) => {
		setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
	};

	const handleOpenModeloChange = async () => {
		setIsLoading(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IBuscarModeloPdfRequerimentoCommand = {
				token: data?.token ?? ""
			};

			const result = await RequerimentosService.buscarModeloPdf(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setIsLoading(false);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					setIsLoading(false);
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				setIsLoading(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setIsLoading(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setIsLoading(false);
			PdfHelper.showPdf(body.data.base64);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoading(false);
			return navigate(-1);
		}
	};

	const handleEnviarAprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const filesBase64: string[] = [];

			for (const file of files) {
				const base64String = await BaseHelper.FileToBase64(file);

				filesBase64.push(base64String);
			}

			const params: IAprovarRequerimentoCommand = {
				token: token ?? "",
				files: filesBase64
			};

			const result = await RequerimentosService.aprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			ToastHelper("success", "Requerimento aprovado com sucesso.");

			setTimeout(() => {
				navigate(-1);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return;
		} finally {
			setIsLoadingIndex(false);
			setDesabilitarBotao(false);
			setOpenDialogAprova(false);
		}
	};

	const handleEnviarReprova = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IReprovarRequerimentoCommand = {
				token: token ?? "",
				motivo_reprovacao: motivoReprovacao
			};

			const result = await RequerimentosService.reprovar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoadingIndex(false);
					return navigate(ERotas.LOGIN);
				}

				setIsLoadingIndex(false);
				setDesabilitarBotao(false);
				setOpenDialogAprova(false);
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				setDesabilitarBotao(false);
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return;
			}

			setDesabilitarBotao(false);
			setOpenDialogReprova(false);

			ToastHelper("success", "Requerimento reprovado com sucesso.");

			setTimeout(() => {
				navigate(-1);
			}, EStrings.TIMEOUT);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
				setOpenDialogReprova(false);

				return;
			}

			ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			setOpenDialogReprova(false);

			return;
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}

			{isLoading &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Requerimentos </a>
					<a className="opacity-60"> Visualizar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					{!isLoadingIndex &&
						<>
							{acesso === EAcesso.MASTER.toString() &&
								<>
									<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
										<div className={"sm:col-span-12 flex justify-center"}>
											<ButtonDefaultComponent
												color={"teal"}
												onClick={() => setOpenTimeline(!openTimeline)}
												description={"Clique aqui para acessar a Timeline do Requerimento"}
												variant={"gradient"}
											/>
										</div>
									</div>

									<Card className={"w-full"}>
										<CardBody className={"w-full m-0 p-0 space-y-2"}>
											<div className="card-body p-5">
												<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
													<div className={"sm:col-span-9"}>
														<Button
															variant="text"
															color={"light-blue"}
															size={"md"}
															className="rounded-full flex items-center gap-3"
															onClick={handlePdfChange}
														>
															<HiOutlineArrowDownTray className={"h-5 w-5"}/>
															Baixar arquivo para assinar
														</Button>
													</div>

													<div className={"sm:col-span-3"}>
														<ButtonGroup size={"md"} variant={"gradient"} color={"white"} fullWidth={true}>
															<Button className="bg-green-600 text-white hover:bg-green-800" onClick={handleOpenDialogAprova}>Aprovar</Button>
															<Button className="bg-red-600 text-white hover:bg-red-800" onClick={handleOpenDialogReprova}>Reprovar</Button>
														</ButtonGroup>
													</div>
												</div>
											</div>

											<Dialog open={openDialogAprova} handler={handleOpenDialogAprova} size={"md"} animate={BaseHelper.AnimationDialog}>
												<DialogHeader>Deseja aprovar o requerimento?</DialogHeader>
												<DialogBody>
													<Typography variant={"lead"} color={"red"}>
														Obs.: Esta ação não poderá ser desfeita.
													</Typography>
													<form id={"formAprovar"} onSubmit={handleEnviarAprova} noValidate>
														<InputDropDragPdfComponent setFiles={setFiles} multiple={true}/>
													</form>
													<List>
														{files.map((file, index) => (
															<ListItem key={index} ripple={false} className={"py-1 pr-1 pl-4"}>
																{file.name} - {BaseHelper.GetSizeFile(file.size)}
																<ListItemSuffix>
																	<IconButton variant="text" color="blue-gray" onClick={() => handleExcluirFile(index)}>
																		<HiOutlineTrash className={"w-5 h-5"}/>
																	</IconButton>
																</ListItemSuffix>
															</ListItem>
														))}
													</List>
												</DialogBody>
												<DialogFooter className={"space-x-2"}>
													<ButtonDefaultComponent color={"red"} description={"Fechar"} onClick={() => setOpenDialogAprova(false)} desabilitar={desabilitarBotao}/>
													<ButtonSubmitComponent color={"green"} description={"Confirmar"} form={"formAprovar"} desabilitar={desabilitarBotao}/>
												</DialogFooter>
											</Dialog>

											<Dialog open={openDialogReprova} handler={() => setOpenDialogReprova(!openDialogReprova)} size={"sm"} animate={BaseHelper.AnimationDialog}>
												<DialogHeader>Deseja reprovar o requerimento?</DialogHeader>
												<DialogBody>
													<Typography variant={"small"} className={"font-monospace text-justify"}>
														Digite abaixo o motivo da reprovação.
													</Typography>
													<Typography variant={"small"} className={"font-monospace text-justify"} color={"red"}>
														Obs.: Esta ação não poderá ser desfeita.
													</Typography>
													&emsp;
													<form id={"formReprovar"} onSubmit={handleEnviarReprova} noValidate>
														<Textarea
															color={"blue"}
															label="Motivo da Reprovação"
															value={motivoReprovacao}
															variant="outlined"
															size={"md"}
															required={true}
															onChange={(e) => setMotivoReprovacao(e.target.value)}
														/>
													</form>
												</DialogBody>
												<DialogFooter className={"space-x-2"}>
													<ButtonDefaultComponent color={"green"} description={"Fechar"} onClick={() => setOpenDialogReprova(false)} desabilitar={desabilitarBotao}/>
													<ButtonSubmitComponent form={"formReprovar"} description={"Confirmar"} color={"red"} desabilitar={desabilitarBotao}/>
												</DialogFooter>
											</Dialog>
										</CardBody>
									</Card>

									{acesso === EAcesso.MASTER.toString() &&
										<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
											<div className={"sm:col-span-12 flex justify-center"}>
												<Button
													variant="gradient"
													color={"light-blue"}
													size={"sm"}
													className="rounded-full flex items-center gap-3"
													onClick={handleOpenModeloChange}
												>
													<HiOutlineArrowTopRightOnSquare className={"h-5 w-5"}/>
													Visualize seu Modelo
												</Button>
											</div>
										</div>
									}
								</>
							}

							<FormularioNovoEditar data={data} textoCriarAtualizar={"Atualizar Requerimento"} novo={false}/>
						</>
					}
				</CardBody>
			</Card>
			<TimelineRequerimentoComponent
				requerimento={data?.token ?? ""}
				nomePrimeiroNubente={data?.nubentes[0].nome ?? ""}
				nomeSegundoNubente={data?.nubentes[1].nome ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
}