import {useNavigate, useParams} from "react-router-dom";
import {FormEvent, useEffect, useState} from "react";
import SpinnerComponent from "components/SpinnerComponent";
import {Breadcrumbs, Card, CardBody, Dialog, DialogFooter, DialogHeader, Typography} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import {DadosBancariosService} from "services/DadosBancariosService";
import {DadosBancarios} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosAtivosResult";
import {AccordionComponent} from "components/AccordionPagamentoComponent";
import AlertaSolicitacaoEdicaoTermoComponent from "../_components/cards/alertaSolicitacaoEdicao";
import InputDropDragImageComponent from "components/InputDropDragComponents/ImageComponent";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import ModalImage from "react-modal-image";
import {ToastHelper} from "helpers/ToastHelper";
import EAcesso from "enums/EAcesso";
import {TermosService} from "services/TermosService";
import {IValidarSolicitacaoEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/IValidarSolicitacaoEdicaoTermoCommand";
import {ISalvarComprovanteEdicaoTermoCommand} from "interfaces/Commands/TermosCommands/ISalvarComprovanteEdicaoTermoCommand";
import { BaseHelper } from "helpers/BaseHelper";

export default function SolicitarEdicaoTermo() {
	const navigate = useNavigate();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const {token} = useParams();
	const acesso = sessionStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
	const [valorPix, setValorPix] = useState<string>("");
	const [listaFormasPagamento, setListaFormasPagamento] = useState<DadosBancarios[]>([]);

	const [base64, setBase64] = useState<string>("");

	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const handleOpen = () => {
		setOpenDialog(!openDialog);
	};

	useEffect(() => {
		validarTermo();
		listarFormasPagamento();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.CELEBRANTE.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const validarTermo = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: IValidarSolicitacaoEdicaoTermoCommand = {
				token: token ?? ""
			};

			const result = await TermosService.validarSolicitacaoEdicao(params, tokenVisao);

			setIsLoadingIndex(false);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (!body.data.valido) {
				ToastHelper("warning", EStrings.TERMO_NAO_AUTENTICADO_ACAO);

				return navigate(-1);
			}
		} catch (error) {
			setIsLoadingIndex(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	const listarFormasPagamento = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const result = await DadosBancariosService.listarAtivos(tokenVisao);

			setIsLoadingIndex(false);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setValorPix(body.data.valorPix);
			setListaFormasPagamento(body.data.dadosBancarios);
		} catch (error) {
			setIsLoadingIndex(false);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	function LogToast(tipo: string, mensagem: string) {
		ToastHelper(tipo, mensagem);

		setDesabilitarBotao(false);
		setOpenDialog(false);
	}

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const params: ISalvarComprovanteEdicaoTermoCommand = {
				token: token ?? "",
				base64: base64
			};

			const result = await TermosService.salvarComprovanteEdicao(params, tokenVisao);

			setDesabilitarBotao(false);

			if (!result) {
				return LogToast("warning", EStrings.ERRO_RESULT);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				errors.forEach((item) => {
					ToastHelper("warning", item.message);
				});

				setOpenDialog(false);

				return;
			}

			const body = result.data;

			if (!body) {
				return LogToast("warning", EStrings.ERRO_BUSCAR_DADOS);
			}

			ToastHelper("success", "Solicitação de Edição enviada com sucesso. Por favor, aguarde a aprovação do administrador para edição do Termo!");
			navigate(-1);
		} catch (error) {
			setDesabilitarBotao(false);

			if (error instanceof Error) {
				return LogToast("error", error.message);
			}

			return LogToast("error", EStrings.ERRO_NAO_MAPEADO);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Termos </a>
					<a className="opacity-60"> Solicitação de Edição </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<AlertaSolicitacaoEdicaoTermoComponent valorPix={valorPix}/>

					{!isLoadingIndex &&
						<>
							<div className={"w-full text-center flex items-center justify-center"}>
								<Typography variant={"h3"}>Formas de Pagamento</Typography>
							</div>

							<AccordionComponent lista={listaFormasPagamento}/>

							<hr/>

							<form id={"formSolicitacaoEdicao"} onSubmit={enviarFormulario} noValidate>
								<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
									<div className={"sm:col-span-9"}>
										<InputDropDragImageComponent setBase64={setBase64} accept={"image/*"}/>
									</div>
									<div className="sm:col-span-3 text-center">
										<ModalImage small={base64} large={base64} alt={"Comprovante de Pagamento"} hideZoom={false}/>
									</div>
								</div>

								<div className="grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-6 mb-1">
									<div className="sm:col-span-12 ml-auto mr-auto">
										<ButtonDefaultComponent description={"Enviar Comprovante"} onClick={handleOpen}/>
										<Dialog open={openDialog} handler={handleOpen} size={"sm"} animate={BaseHelper.AnimationDialog}>
											<DialogHeader>Tem certeza que deseja enviar este comprovante?</DialogHeader>
											<DialogFooter className={"space-x-2"}>
												<ButtonDefaultComponent color={"red"} description={"Cancelar"} onClick={handleOpen} desabilitar={desabilitarBotao}/>
												<ButtonSubmitComponent form={"formSolicitacaoEdicao"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
											</DialogFooter>
										</Dialog>
									</div>
								</div>
							</form>
						</>
					}
				</CardBody>
			</Card>
		</>
	);
}