import {GridColDef} from "@mui/x-data-grid";
import {DataHelper} from "helpers/DataHelper";
import {Button, Card, CardBody, Menu, MenuHandler, MenuList, Typography} from "@material-tailwind/react";
import {IconButton} from "@mui/material";
import TableComponent from "components/TableComponent";
import EAcesso from "enums/EAcesso";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {IListarTermoResult} from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import {EStatusTermo} from "enums/EStatusTermo";
import ERotas from "enums/ERotas";
import {HiOutlineDownload, HiOutlineExclamation, HiOutlinePencilAlt} from "react-icons/hi";
import ModalTermoReprovado from "../../modal/reprovado";
import ModalTermoVisualizarComprovante from "../../modal/visualizarComprovante";
import ModalTermoVisualizarCancelamento from "../../modal/visualizarCancelamento";
import ModalTermoSolicitarCancelamento from "../../modal/solicitarCancelamento";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import {TermosService} from "services/TermosService";
import {IDownloadTermoFinalizadoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoFinalizadoCommand";
import {IDownloadTermoAprovadoCommand} from "interfaces/Commands/TermosCommands/IDownloadTermoAprovadoCommand";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import ModalTermoSolicitarFinalizacao from "../../modal/solicitarFinalizacao";
import ModalTermoVisualizarFinaliza from "../../modal/visualizarFinalizacao";
import ModalTermoRollback from "../../modal/rollback";
import {HiOutlineArrowUturnLeft, HiOutlineArrowUturnUp, HiOutlineChevronUpDown, HiOutlineDocumentCheck, HiOutlineExclamationCircle, HiOutlinePencilSquare, HiOutlineStar, HiOutlineTrash} from "react-icons/hi2";
import {FileHelper} from "helpers/FileHelper";
import {TimelineTermoComponent} from "../../timelineComponent";
import ModalTermoSolicitarReenvioFinalizacao from "../../modal/solicitarReenvioFinalizacao";

interface IProps {
	data: IListarTermoResult[];
	setIsLoadingIndex: React.Dispatch<React.SetStateAction<boolean>>;
	setAtualizarLista: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TabelaTermo(props: IProps) {
	const navigate = useNavigate();

	const {data, setIsLoadingIndex, setAtualizarLista} = props;

	const acesso = sessionStorage.getItem("acesso");
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const token = sessionStorage.getItem("token");

	const [termo, setTermo] = useState<IListarTermoResult>();
	const [isOpenModalReprovado, setIsOpenModalReprovado] = useState<boolean>(false);
	const [isOpenModalVisualizarComprovante, setIsOpenModalVisualizarComprovante] = useState<boolean>(false);
	const [isOpenModalSolicitarCancelamento, setIsOpenModalSolicitarCancelamento] = useState<boolean>(false);
	const [isOpenModalVisualizarCancelamento, setIsOpenModalVisualizarCancelamento] = useState<boolean>(false);
	const [isOpenModalFinaliza, setIsOpenModalFinaliza] = useState<boolean>(false);
	const [isOpenModalVisualizarFinaliza, setIsOpenModalVisualizarFinaliza] = useState<boolean>(false);
	const [isOpenModalVisualizarComprovanteReenvio, setIsOpenModalVisualizarComprovanteReenvio] = useState<boolean>(false);
	const [isOpenModalRollback, setIsOpenModalRollback] = useState<boolean>(false);
	const [isOpenModalSolicitaReenvioFinalizacao, setIsOpenModalSolicitaReenvioFinalizacao] = useState<boolean>(false);
	const [openTimeline, setOpenTimeline] = useState<boolean>(false);

	const handleDownload = async (tokenTermo: string, status: number) => {
		setIsLoadingIndex(true);

		if (status == EStatusTermo.FINALIZADO) {
			const params: IDownloadTermoFinalizadoCommand = {
				token: tokenTermo
			};

			try {
				const result = await TermosService.downloadFinalizado(params, tokenVisao ?? "");

				if (!result) {
					setIsLoadingIndex(false);
					ToastHelper("warning", EStrings.ERRO_RESULT);

					return;
				}

				const errors = result.data.errors;

				if (errors.length > 0) {
					const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

					if (error !== undefined) {
						LogErrorHelper.redirectToLogin("warning", errors[0].message);

						return navigate(ERotas.LOGIN);
					}

					ToastHelper("warning", errors[0].message);
					setIsLoadingIndex(false);
					return;
				}

				const body = result.data;

				if (!body) {
					ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
					setIsLoadingIndex(false);
					return;
				}

				setIsLoadingIndex(false);
				ToastHelper("success", EStrings.DOWNLOAD_SUCESSO);
				FileHelper.download(body.data.base64, "termo_finalizado.zip");
			} catch (error) {
				setIsLoadingIndex(false);

				if (error instanceof Error) {
					ToastHelper("error", error.message);
				} else {
					ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
				}

				return;
			}
		} else if (status == EStatusTermo.APROVADO || status == EStatusTermo.SOLICITACAO_FINALIZACAO) {
			const params: IDownloadTermoAprovadoCommand = {
				token: tokenTermo
			};

			try {
				const result = await TermosService.downloadAprovado(params, tokenVisao ?? "");

				if (!result) {
					ToastHelper("warning", EStrings.ERRO_RESULT);
					setIsLoadingIndex(false);
					return;
				}

				const errors = result.data.errors;

				if (errors.length > 0) {
					const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

					if (error !== undefined) {
						LogErrorHelper.redirectToLogin("warning", errors[0].message);
						setIsLoadingIndex(false);
						return navigate(ERotas.LOGIN);
					}

					ToastHelper("warning", errors[0].message);
					setIsLoadingIndex(false);
					return;
				}

				const body = result.data;

				if (!body) {
					ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);
					setIsLoadingIndex(false);
					return;
				}

				setIsLoadingIndex(false);
				ToastHelper("success", EStrings.DOWNLOAD_SUCESSO);
				FileHelper.download(body.data.base64, "termo_aprovado.zip");
			} catch (error) {
				setIsLoadingIndex(false);

				if (error instanceof Error) {
					ToastHelper("error", error.message);
				} else {
					ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
				}

				return;
			}
		}
	};

	const openModalFinaliza = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();

		setIsOpenModalFinaliza(true);
	};

	const openModalReprovado = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();

		setIsOpenModalReprovado(true);
	};

	const openModalVisualizarComprovante = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalVisualizarComprovante(true);
	};

	const openModalSolicitarCancelamento = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalSolicitarCancelamento(true);
	};

	const openModalVisualizarCancelamento = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalVisualizarCancelamento(true);
	};

	const openModalVisualizarFinaliza = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalVisualizarFinaliza(true);
	};

	const openModalVisualizarReenvioFinalizacao = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalVisualizarComprovanteReenvio(true);
	};

	const openModalRollback = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalRollback(true);
	};

	const openModalSolicitaReenvioFinalizacao = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setIsOpenModalSolicitaReenvioFinalizacao(true);
	};

	const handleOpenTimeline = (termo: IListarTermoResult) => {
		setTermo(termo);
		fecharTodosModais();
		setOpenTimeline(true);
	};

	const fecharTodosModais = () => {
		setIsOpenModalReprovado(false);
		setIsOpenModalVisualizarComprovante(false);
		setIsOpenModalSolicitarCancelamento(false);
		setIsOpenModalVisualizarCancelamento(false);
		setIsOpenModalFinaliza(false);
		setIsOpenModalVisualizarFinaliza(false);
		setIsOpenModalVisualizarComprovanteReenvio(false);
		setIsOpenModalRollback(false);
		setIsOpenModalSolicitaReenvioFinalizacao(false);
	};

	const columns: GridColDef[] = [
		{
			field: "id", headerName: "ID", width: 50, renderCell: (params) => {
				return `#${params.row.id}`;
			}
		},
		{
			field: "prazo", headerName: "Prazo", width: 130, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value, "DD/MM/YY HH:mm");
			}
		},
		{
			field: "data_casamento", headerName: "Data", width: 80, valueGetter: (params) => {
				return DataHelper.formatDateString(params.value);
			}
		}
	];

	if (acesso === EAcesso.CELEBRANTE.toString()) {
		columns.push(
			{
				field: "hora_casamento", headerName: "Hora", width: 100, valueGetter: (params) => {
					return DataHelper.formatHourString(params.value);
				}
			}
		);
	}

	if (acesso === EAcesso.MASTER.toString()) {
		columns.push(
			{field: "nome_solicitante", headerName: "Solicitante", width: 200, cellClassName: "multiline-cell"},
		);
	}

	columns.push(
		{
			field: "nubentes", headerName: "Nubentes", minWidth: 250, flex: 100, cellClassName: "multiline-cell", valueGetter: (params) => {
				return `${params.row.nome_primeiro_nubente} - ${params.row.nome_segundo_nubente}`;
			}
		},
		{field: "descricao_status", headerName: "Status", width: 120, cellClassName: "multiline-cell"},
		{
			field: "acoes", headerName: "Ações", headerAlign: "center", width: 75, align: "center", renderCell: (params) => {
				const handleRedirect = (rota: string) => {
					navigate(rota);
				};

				return (
					<>
						{params.row.status != EStatusTermo.CANCELADO &&
							<Menu>
								<MenuHandler>
									<IconButton>
										<HiOutlineChevronUpDown/>
									</IconButton>
								</MenuHandler>
								<MenuList className={"space-y-2"}>
									{(params.row.status == EStatusTermo.PENDENTE || params.row.status == EStatusTermo.AGUARDANDO) && acesso == EAcesso.CELEBRANTE.toString() && params.row.token_solicitante == token &&
										<>
											<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"amber"} onClick={() => handleRedirect(`${params.row.termo_pronto ? ERotas.TERMO_EDITAR_PRONTO : ERotas.TERMO_EDITAR}/${params.row.token}`)}>
												<HiOutlinePencilSquare className={"w-4 h-4"}/>
												<Typography variant="small" className="font-normal">
													Editar
												</Typography>
											</Button>
										</>
									}

									{params.row.status == EStatusTermo.PENDENTE && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"green"} onClick={() => handleRedirect(`${ERotas.TERMO_VISUALIZAR}/${params.row.token}`)}>
											<HiOutlineDocumentCheck className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Visualizar
											</Typography>
										</Button>
									}

									{(params.row.status == EStatusTermo.APROVADO || params.row.status == EStatusTermo.SOLICITACAO_FINALIZACAO || params.row.status == EStatusTermo.FINALIZADO) &&
										<>
											<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"blue"} onClick={() => handleDownload(params.row.token, params.row.status)}>
												<HiOutlineDownload className={"w-4 h-4"}/>
												<Typography variant={"small"} className={"font-normal"}>
													Baixar
												</Typography>
											</Button>

											{params.row.status == EStatusTermo.APROVADO && params.row.token_solicitante == token && acesso == EAcesso.CELEBRANTE.toString() &&
												<>
													<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"green"} onClick={() => handleRedirect(`${ERotas.TERMO_SOLICITAR_EDICAO}/${params.row.token}`)}>
														<HiOutlinePencilAlt className={"w-4 h-4"}/>
														<Typography variant="small" className="font-normal">
															Edição
														</Typography>
													</Button>

													<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"teal"} onClick={() => openModalFinaliza(params.row)}>
														<HiOutlineStar className={"w-4 h-4"}/>
														<Typography variant={"small"} className={"font-normal"}>
															Finalizar
														</Typography>
													</Button>
												</>
											}

											{params.row.status == EStatusTermo.SOLICITACAO_FINALIZACAO && acesso == EAcesso.MASTER.toString() &&
												<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"teal"} onClick={() => openModalVisualizarFinaliza(params.row)}>
													<HiOutlineStar className={"w-4 h-4"}/>
													<Typography variant={"small"} className={"font-normal"}>
														Finalizar
													</Typography>
												</Button>
											}
										</>
									}

									{params.row.status == EStatusTermo.REPROVADO &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"cyan"} onClick={() => openModalReprovado(params.row)}>
											<HiOutlineExclamation className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Reprovado
											</Typography>
										</Button>
									}

									{params.row.status == EStatusTermo.EDICAO && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-around"} size={"sm"} variant={"gradient"} color={"cyan"} onClick={() => openModalVisualizarComprovante(params.row)}>
											<HiOutlineExclamationCircle className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Visualizar Comprovante
											</Typography>
										</Button>
									}

									{params.row.id_termo == null && token === params.row.token_solicitante && acesso === EAcesso.CELEBRANTE.toString() && (params.row.status !== EStatusTermo.CANCELADO && params.row.status !== EStatusTermo.SOLICITACAO_CANCELAMENTO && params.row.status !== EStatusTermo.FINALIZADO) &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"red"} onClick={() => openModalSolicitarCancelamento(params.row)}>
											<HiOutlineTrash className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Cancelar
											</Typography>
										</Button>
									}

									{params.row.status == EStatusTermo.SOLICITACAO_CANCELAMENTO && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"red"} onClick={() => openModalVisualizarCancelamento(params.row)}>
											<HiOutlineTrash className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Cancelar
											</Typography>
										</Button>
									}

									{params.row.status == EStatusTermo.REPROVADO_FINALIZACAO && acesso == EAcesso.CELEBRANTE.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"purple"} onClick={() => openModalSolicitaReenvioFinalizacao(params.row)}>
											<HiOutlineArrowUturnUp/>
											<Typography variant={"small"} className={"font-normal"}>
												Reenviar P/ Finalização
											</Typography>
										</Button>
									}

									{params.row.status == EStatusTermo.SOLICITACAO_REENVIO_FINALIZACAO && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"purple"} onClick={() => openModalVisualizarReenvioFinalizacao(params.row)}>
											<HiOutlineArrowUturnUp/>
											<Typography variant={"small"} className={"font-normal"}>
												Visualizar Comprovante Finalização
											</Typography>
										</Button>
									}

									{(params.row.status == EStatusTermo.APROVADO || params.row.status == EStatusTermo.REPROVADO) && acesso == EAcesso.MASTER.toString() &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"teal"} onClick={() => openModalRollback(params.row)}>
											<HiOutlineArrowUturnLeft className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Rollback
											</Typography>
										</Button>
									}

									{params.row.token &&
										<Button fullWidth={true} className={"items-center flex justify-between"} size={"sm"} variant={"gradient"} color={"white"} onClick={() => handleOpenTimeline(params.row)}>
											<HiOutlineStar className={"w-4 h-4"}/>
											<Typography variant={"small"} className={"font-normal"}>
												Timeline
											</Typography>
										</Button>
									}
								</MenuList>
							</Menu>
						}
					</>
				);
			}
		}
	);

	return (
		<>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<TableComponent columns={columns} rows={data}/>
					<ModalTermoReprovado termo={termo} open={isOpenModalReprovado} setOpen={setIsOpenModalReprovado}/>
					<ModalTermoVisualizarComprovante termo={termo} open={isOpenModalVisualizarComprovante} setOpen={setIsOpenModalVisualizarComprovante} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoSolicitarCancelamento termo={termo} open={isOpenModalSolicitarCancelamento} setOpen={setIsOpenModalSolicitarCancelamento} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoVisualizarCancelamento termo={termo} open={isOpenModalVisualizarCancelamento} setOpen={setIsOpenModalVisualizarCancelamento} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoSolicitarFinalizacao termo={termo} open={isOpenModalFinaliza} setOpen={setIsOpenModalFinaliza} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoVisualizarFinaliza termo={termo} open={isOpenModalVisualizarFinaliza} setOpen={setIsOpenModalVisualizarFinaliza} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoRollback termo={termo} open={isOpenModalRollback} setOpen={setIsOpenModalRollback} setAtualizarLista={setAtualizarLista}/>
					<ModalTermoSolicitarReenvioFinalizacao termo={termo} open={isOpenModalSolicitaReenvioFinalizacao} setOpen={setIsOpenModalSolicitaReenvioFinalizacao} setAtualizarLista={setAtualizarLista}/>
				</CardBody>
			</Card>
			<TimelineTermoComponent
				termo={termo?.token ?? ""}
				nomePrimeiroNubente={termo?.nome_primeiro_nubente ?? ""}
				nomeSegundoNubente={termo?.nome_segundo_nubente ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
}