import {Dialog, DialogBody, DialogFooter, DialogHeader} from "@material-tailwind/react";
import ButtonSubmitComponent from "components/Buttons/SubmitComponent";
import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import InputComponent from "components/InputComponent";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import EStrings from "enums/EStrings";
import {ToastHelper} from "helpers/ToastHelper";
import ERotas from "enums/ERotas";
import {useNavigate} from "react-router-dom";
import {ParametrosService} from "services/ParametrosService";
import {IListarDadosBancariosResult} from "interfaces/CommandsResults/DadosBancariosResults/IListarDadosBancariosResult";
import {ISalvarAtualizarDadoBancarioCommand} from "interfaces/Commands/DadosBancariosCommands/ISalvarAtualizarDadoBancarioCommand";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {DadosBancariosService} from "services/DadosBancariosService";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import { BaseHelper } from "helpers/BaseHelper";

interface IProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	titulo: string;
	data: IListarDadosBancariosResult | null;
	setAtualizarLista: Dispatch<SetStateAction<boolean>>;
	setIsLoadingIndex: Dispatch<SetStateAction<boolean>>;
}

export function FormularioDadoBancarioComponent(props: IProps) {
	const navigate = useNavigate();
	const token = sessionStorage.getItem("tokenLogado");

	const {open, setOpen, titulo, data, setAtualizarLista, setIsLoadingIndex} = props;
	const [desabilitarBotao, setDesabilitarBotao] = useState<boolean>(false);

	const [banco, setBanco] = useState<string>("");
	const [agencia, setAgencia] = useState<string>("");
	const [conta, setConta] = useState<string>("");
	const [tipoCpfCnpj, setTipoCpfCnpj] = useState<string>("");
	const [tipoCpfCnpjObject, setTipoCpfCnpjObject] = useState<IBuscarComboboxResult | null>(null);
	const [cpfCnpj, setCpfCnpj] = useState<string>("");
	const [tipoChave, setTipoChave] = useState<string>("");
	const [tipoChaveObject, setTipoChaveObject] = useState<IBuscarComboboxResult | null>(null);
	const [chave, setChave] = useState<string>("");

	const [listaTiposCpfCnpj, setListaTiposCpfCnpj] = useState<IBuscarComboboxResult[]>([]);
	const [listaTiposChave, setListaTiposChave] = useState<IBuscarComboboxResult[]>([]);

	const [maskCpfCnpj, setMaskCpfCnpj] = useState<string>("");
	const [maskChave, setMaskChave] = useState<string>("");

	useEffect(() => {
		buscarCombobox();
	}, []);

	useEffect(() => {
		setBanco(data?.banco ?? "");
		setAgencia(data?.agencia ?? "");
		setConta(data?.conta ?? "");
		setTipoCpfCnpj(data?.tipo_cpf_cnpj ?? "");
		setCpfCnpj(data?.cpf_cnpj ?? "");
		setTipoChave(data?.tipo_chave ?? "");
		setChave(data?.chave ?? "");
	}, [data]);

	useEffect(() => {
		const selectedOption = listaTiposCpfCnpj.find((option) => option.sigla === tipoCpfCnpj);

		setTipoCpfCnpjObject(selectedOption ?? null);
	}, [tipoCpfCnpj, listaTiposCpfCnpj]);

	useEffect(() => {
		const selectedOption = listaTiposChave.find((option) => option.sigla === tipoChave);

		setTipoChaveObject(selectedOption ?? null);
	}, [tipoChave, listaTiposChave]);

	const buscarCombobox = async () => {
		setIsLoadingIndex(true);

		try {
			if (!token || token === "") {
				LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

				setIsLoadingIndex(false);
				return navigate(ERotas.LOGIN);
			}

			const result = await ParametrosService.buscarCombobox({relatorio: "DADOS_BANCARIOS"}, token);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.DADOS_BANCARIOS_LISTAR);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setDesabilitarBotao(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.DADOS_BANCARIOS_LISTAR);
			}

			const data = body.data;

			popularCombobox(data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.DADOS_BANCARIOS_LISTAR);
		}
	};

	function popularCombobox(dados: IBuscarComboboxResult[]) {
		const tiposCpfCnpj = dados.filter(item => item.relatorio.includes("TIPOS_CPF_CNPJ"));
		const tiposChaves = dados.filter(item => item.relatorio.includes("TIPOS_CHAVES"));

		setListaTiposCpfCnpj(tiposCpfCnpj);
		setListaTiposChave(tiposChaves);
	}

	const enviarFormulario = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setDesabilitarBotao(true);

		if (!token || token === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		try {
			const params: ISalvarAtualizarDadoBancarioCommand = {
				token: data?.token ?? "",
				banco: banco,
				agencia: agencia,
				conta: conta,
				tipo_cpf_cnpj: tipoCpfCnpjObject?.token ?? "",
				cpf_cnpj: cpfCnpj,
				tipo_chave: tipoChaveObject?.token ?? "",
				chave: chave
			};

			const result = await DadosBancariosService.salvarAtualizar(params, token);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);
				setDesabilitarBotao(false);

				return;
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setDesabilitarBotao(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setDesabilitarBotao(false);

				return;
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setDesabilitarBotao(false);
				return;
			}

			setDesabilitarBotao(false);
			setOpen(false);

			ToastHelper("success", `Dado Bancário ${params.token == "" ? "criado" : "atualizado"} com sucesso!`);

			setAtualizarLista(true);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	const handleTipoCpfCnpj = (newValue: IBuscarComboboxResult | null) => {
		if (newValue !== null) {
			if (newValue.sigla === "PJ") {
				setMaskCpfCnpj("99.999.999/9999-99");
			} else {
				setMaskCpfCnpj("999.999.999-99");
			}
		}

		setTipoCpfCnpjObject(newValue);
	};

	const handleTipoChave = (newValue: IBuscarComboboxResult | null) => {
		if (newValue !== null) {
			switch (newValue.sigla) {
			case "PF":
				setMaskChave("999.999.999-99");
				break;
			case "PJ":
				setMaskChave("99.999.999/9999-99");
				break;
			case "CEL":
				setMaskChave("(99) 99999-9999");
				break;
			default:
				setMaskChave("");
				break;
			}
		}

		setTipoChaveObject(newValue);
	};

	const handleFecharModal = () => {
		setBanco("");
		setAgencia("");
		setConta("");
		setTipoCpfCnpj("");
		setCpfCnpj("");
		setTipoChave("");
		setChave("");

		setOpen(!open);
	};

	return (
		<>
			<Dialog open={open} handler={() => setOpen(!open)} size={"sm"} animate={BaseHelper.AnimationDialog}>
				<DialogHeader>{titulo}</DialogHeader>
				<DialogBody>
					<form id={"formDadosBancarios"} onSubmit={enviarFormulario} noValidate>
						<div className={"grid w-full gap-x-6 gap-y-8 sm:grid-cols-12 mt-4 mb-3"}>
							<div className={"sm:col-span-12"}>
								<InputComponent type={"text"} label={"Banco"} value={banco} required={true} onChange={(e) => setBanco(e.target.value)}/>
							</div>
							<div className={"sm:col-span-6"}>
								<InputComponent type={"text"} label={"Agência"} value={agencia} required={true} onChange={(e) => setAgencia(e.target.value)}/>
							</div>
							<div className={"sm:col-span-6"}>
								<InputComponent type={"text"} label={"Conta"} value={conta} required={true} onChange={(e) => setConta(e.target.value)}/>
							</div>
							<div className={"sm:col-span-6"}>
								<Autocomplete
									color={tipoCpfCnpj != "" ? "success" : "info"}
									disablePortal
									options={listaTiposCpfCnpj}
									getOptionLabel={(option) => option.descricao}
									value={tipoCpfCnpjObject}
									size={"small"}
									renderInput={(params) => <TextField {...params} label={"Tipo"}/>}
									onChange={(_, newValue) => handleTipoCpfCnpj(newValue)}
								/>
							</div>
							<div className={"sm:col-span-6"}>
								<InputMask mask={maskCpfCnpj} maskPlaceholder={null} value={cpfCnpj} onChange={(event) => setCpfCnpj(event.target.value)}>
									<InputComponent
										type={"text"}
										label={"CPF/CNPJ"}
										value={cpfCnpj}
										required={true}
									/>
								</InputMask>
							</div>
							<div className={"sm:col-span-12"}>
								<Autocomplete
									color={tipoChave != "" ? "success" : "info"}
									disablePortal
									options={listaTiposChave}
									getOptionLabel={(option) => option.descricao}
									value={tipoChaveObject}
									size={"small"}
									renderInput={(params) => <TextField {...params} label={"Tipo de Chave"}/>}
									onChange={(_, newValue) => handleTipoChave(newValue)}
								/>
							</div>
							<div className={"sm:col-span-12"}>
								<InputMask mask={maskChave} maskPlaceholder={null} value={chave} onChange={(event) => setChave(event.target.value)}>
									<InputComponent
										type={"text"}
										label={"Chave"}
										value={chave}
										required={true}
									/>
								</InputMask>
							</div>
						</div>
					</form>
				</DialogBody>
				<DialogFooter className={"space-x-2"}>
					<ButtonDefaultComponent color={"red"} description={"Cancelar"} onClick={() => handleFecharModal()}/>
					<ButtonSubmitComponent form={"formDadosBancarios"} color={"green"} description={"Confirmar"} desabilitar={desabilitarBotao}/>
				</DialogFooter>
			</Dialog>
		</>
	);
}