import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import EStrings from "enums/EStrings";
import ERotas from "enums/ERotas";
import SpinnerComponent from "components/SpinnerComponent";
import {Breadcrumbs} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {TermosService} from "services/TermosService";
import TabelaTermo from "../../_components/tabelas/listarTermos";
import { IListarTermosCommand } from "interfaces/Commands/TermosCommands/IListarTermosCommand";
import { EStatusTermo } from "enums/EStatusTermo";
import { IListarTermoResult } from "interfaces/CommandsResults/TermosResults/IListarTermoResult";
import { ToastHelper } from "helpers/ToastHelper";

export default function ListarTermosSolicitacaoCancelamento() {
	const navigate = useNavigate();
	const tokenVisao = sessionStorage.getItem("tokenLogado");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [lista, setLista] = useState<IListarTermoResult[]>([]);
	const [atualizarLista, setAtualizarLista] = useState<boolean>(false);

	useEffect(() => {
		listarTermos();
	}, []);

	useEffect(() => {
		listarTermos();
	}, [atualizarLista]);

	const listarTermos = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		try {
			const params: IListarTermosCommand = {
				status: [EStatusTermo.SOLICITACAO_CANCELAMENTO]
			};

			const result = await TermosService.listar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);
					setIsLoadingIndex(false);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				setIsLoadingIndex(false);
				return navigate(ERotas.PRINCIPAL);
			}

			setLista(body.data);
			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			setIsLoadingIndex(false);
			return navigate(ERotas.PRINCIPAL);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a className="opacity-60"> Termos </a>
					<a className="opacity-60"> Solicitação de Cancelamento </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>

			<TabelaTermo data={lista} setIsLoadingIndex={setIsLoadingIndex} setAtualizarLista={setAtualizarLista}/>
		</>
	);
}