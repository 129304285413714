import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IBuscarTermoResult} from "interfaces/CommandsResults/TermosResults/IBuscarTermoResult";
import EAcesso from "enums/EAcesso";
import {ToastHelper} from "helpers/ToastHelper";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import ERotas from "enums/ERotas";
import {IBuscarTermoCommand} from "interfaces/Commands/TermosCommands/IBuscarTermoCommand";
import {TermosService} from "services/TermosService";
import {EStatusTermo} from "enums/EStatusTermo";
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import SpinnerComponent from "components/SpinnerComponent";
import {TermoFormulario} from "./_components/formulario";
import {TermoSemAssinatura} from "./_components/semAssinatura";
import {TimelineTermoComponent} from "../_components/timelineComponent";

export default function VisualizarTermo() {
	const navigate = useNavigate();
	const {token} = useParams();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const acesso = sessionStorage.getItem("acesso");

	const [openTimeline, setOpenTimeline] = useState<boolean>(false);

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);
	const [isLoadingCard, setIsLoadingCard] = useState<boolean>(false);
	const [data, setData] = useState<IBuscarTermoResult>();

	useEffect(() => {
		buscarDados();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.MASTER.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(-1);
		}
	}, [acesso]);

	const buscarDados = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(ERotas.LOGIN);
		}

		if (!token || token === "") {
			ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			return navigate(-1);
		}

		try {
			setIsLoadingIndex(false);

			const params: IBuscarTermoCommand = {
				token: token
			};

			const result = await TermosService.buscar(params, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			if (body.data.status !== EStatusTermo.PENDENTE) {
				ToastHelper("warning", EStrings.TERMO_NAO_AUTENTICADO_ACAO);

				return navigate(-1);
			}

			setData(body.data);
			setIsLoadingCard(true);
		} catch (error) {
			setIsLoadingIndex(true);

			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}

			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Termos </a>
					<a className="opacity-60"> Visualizar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					{isLoadingCard &&
						<>
							{acesso === EAcesso.MASTER.toString() &&
								<>
									<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
										<div className={"sm:col-span-12 flex justify-center"}>
											<ButtonDefaultComponent
												color={"teal"}
												onClick={() => setOpenTimeline(!openTimeline)}
												description={"Clique aqui para acessar a Timeline do Termo"}
												variant={"gradient"}
											/>
										</div>
									</div>

									{!data?.is_termo_pronto &&
										<TermoFormulario data={data} setIsLoading={setIsLoadingIndex} token={token ?? ""}/>
									}

									{data?.is_termo_pronto &&
										<TermoSemAssinatura setIsLoading={setIsLoadingIndex} token={token ?? ""}/>
									}
								</>
							}
						</>
					}
				</CardBody>
			</Card>

			<TimelineTermoComponent
				termo={data?.token ?? ""}
				nomePrimeiroNubente={data?.nubentes[0].nome ?? ""}
				nomeSegundoNubente={data?.nubentes[1].nome ?? ""}
				open={openTimeline}
				setOpen={setOpenTimeline}
			/>
		</>
	);
}