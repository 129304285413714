import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

/* COMPONENTS */
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import SpinnerComponent from "components/SpinnerComponent";

/* HELPERS E SERVICES */
import {UsuariosService} from "services/UsuariosService";

/* INTERFACES, COMMANDS E ENUMS */
import EStrings from "enums/EStrings";
import {IBuscarDadosUsuarioResult} from "interfaces/CommandsResults/UsuariosResults/IBuscarDadosUsuarioResult";
import ERotas from "enums/ERotas";
import Formulario from "../_components/formulario";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import {ToastHelper} from "helpers/ToastHelper";
import EAcesso from "enums/EAcesso";

export default function EditarUsuario() {
	const navigate = useNavigate();
	const {token} = useParams();
	const tokenVisao = sessionStorage.getItem("tokenLogado");
	const acesso = sessionStorage.getItem("acesso");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(true);
	const [data, setData] = useState<IBuscarDadosUsuarioResult>();
	const [isAdmin, setIsAdmin] = useState<boolean>(false);

	useEffect(() => {
		buscarDadosUsuario();

		if (acesso !== null) {
			setIsAdmin(acesso === EAcesso.MASTER.toString());
		}
	}, []);

	const buscarDadosUsuario = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(ERotas.LOGIN);
		}

		if (!token || token === "") {
			ToastHelper("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			setIsLoadingIndex(false);
			return navigate(-1);
		}

		try {
			const result = await UsuariosService.buscarDadosUsuario(
				{token: token},
				tokenVisao
			);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				setIsLoadingIndex(false);
				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					setIsLoadingIndex(false);
					return navigate(ERotas.LOGIN);
				}

				ToastHelper("warning", errors[0].message);
				setIsLoadingIndex(false);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			setData(body.data);

			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}
			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Usuários </a>
					<a className="opacity-60"> Editar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					{!isLoadingIndex &&
						<Formulario data={data} novo={false} textoCriarAtualizar={"Atualizar Usuário"} isAdmin={isAdmin}/>
					}
				</CardBody>
			</Card>
		</>
	);
}