import EAcesso from "enums/EAcesso";
import EStrings from "enums/EStrings";
import {LogErrorHelper} from "helpers/LogErrorHelper";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ERotas from "enums/ERotas";
import {TermosService} from "services/TermosService";
import {Breadcrumbs, Card, CardBody} from "@material-tailwind/react";
import ButtonDefaultComponent from "components/Buttons/DefaultComponent";
import {ParametrosService} from "services/ParametrosService";
import {IBuscarComboboxResult} from "interfaces/CommandsResults/ParametrosResults/IBuscarComboboxResult";
import {RequerimentosService} from "services/RequerimentosService";
import {IRequerimentosAprovadosSemTermoResult} from "interfaces/CommandsResults/RequerimentosResults/IRequerimentosAprovadosSemTermoResult";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormularioNovoEditar from "../_components/formularios/novoEditar";
import {FormularioSemAssinatura} from "../_components/formularios/semAssinatura";
import SpinnerComponent from "components/SpinnerComponent";
import {ToastHelper} from "helpers/ToastHelper";

export default function AdicionarTermo() {
	const navigate = useNavigate();
	const acesso = sessionStorage.getItem("acesso");
	const tokenVisao = sessionStorage.getItem("tokenLogado");

	const [isLoadingIndex, setIsLoadingIndex] = useState<boolean>(false);

	const [listaFormasEnvio, setListaFormasEnvio] = useState<IBuscarComboboxResult[]>([]);
	const [listaRequerimentos, setListaRequerimentos] = useState<IRequerimentosAprovadosSemTermoResult[]>([]);

	const [requerimentoObject, setRequerimentoObject] = useState<IRequerimentosAprovadosSemTermoResult | null>(null);
	const [formaEnvioObject, setFormaEnvioObject] = useState<IBuscarComboboxResult | null>(null);

	const [visualizarPreenchimentoFormulario, setVisualizarPreenchimentoFormulario] = useState<boolean>(false);
	const [visualizarTermoSemAssinatura, setVisualizarTermoSemAssinatura] = useState<boolean>(false);

	useEffect(() => {
		verificaCriacao();
		listarRequerimentosSemTermo();
		buscarCombobox();
	}, []);

	useEffect(() => {
		if (acesso != EAcesso.CELEBRANTE.toString()) {
			ToastHelper("warning", EStrings.USUARIO_NAO_AUTENTICADO_ACAO);

			return navigate(ERotas.PRINCIPAL);
		}
	}, [acesso]);

	const verificaCriacao = async () => {
		setIsLoadingIndex(true);

		if (!tokenVisao || tokenVisao === "") {
			LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);

			navigate(ERotas.LOGIN);
			return;
		}

		try {
			const result = await TermosService.validarCriacao(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				const error = errors.find((error) => error.message === EStrings.USUARIO_NAO_LOCALIZADO);

				if (error !== undefined) {
					LogErrorHelper.redirectToLogin("warning", errors[0].message);

					navigate(ERotas.LOGIN);
					return;
				}

				ToastHelper("warning", errors[0].message);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.PRINCIPAL);
			}

			if (!body.data.valido) {
				ToastHelper("warning", EStrings.REQUERIMENTO_NAO_AUTENTICADO_ACAO);

				return navigate(ERotas.PRINCIPAL);
			}

			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	const listarRequerimentosSemTermo = async () => {
		setIsLoadingIndex(true);

		try {
			if (!tokenVisao || tokenVisao === "") {
				return LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);
			}

			const result = await RequerimentosService.listarAprovadosSemTermo(tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(ERotas.PRINCIPAL);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				return navigate(ERotas.PRINCIPAL);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(ERotas.PRINCIPAL);
			}

			if (body.data.length <= 0) {
				ToastHelper("warning", EStrings.REQUERIMENTO_FALHA_LISTAR);

				return navigate(ERotas.PRINCIPAL);
			}

			setListaRequerimentos(body.data);

			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(ERotas.PRINCIPAL);
		}
	};

	const buscarCombobox = async () => {
		setIsLoadingIndex(true);

		try {
			if (!tokenVisao || tokenVisao === "") {
				return LogErrorHelper.redirectToLogin("warning", EStrings.USUARIO_NAO_LOCALIZADO);
			}

			const result = await ParametrosService.buscarCombobox({relatorio: "FORMA_TERMO_ENVIO"}, tokenVisao);

			if (!result) {
				ToastHelper("warning", EStrings.ERRO_RESULT);

				return navigate(-1);
			}

			const errors = result.data.errors;

			if (errors.length > 0) {
				ToastHelper("warning", errors[0].message);

				return navigate(-1);
			}

			const body = result.data;

			if (!body) {
				ToastHelper("warning", EStrings.ERRO_BUSCAR_DADOS);

				return navigate(-1);
			}

			const data = body.data;

			popularCombobox(data);

			setIsLoadingIndex(false);
		} catch (error) {
			if (error instanceof Error) {
				ToastHelper("error", error.message);
			} else {
				ToastHelper("error", EStrings.ERRO_NAO_MAPEADO);
			}

			return navigate(-1);
		}
	};

	function popularCombobox(dados: IBuscarComboboxResult[]) {
		const listaFormasEnvio = dados.filter(item => item.relatorio.includes("FORMAS_ENVIO"));

		setListaFormasEnvio(listaFormasEnvio);
	}

	function handleFormaEnvio(newValue: IBuscarComboboxResult | null) {
		setFormaEnvioObject(newValue);

		setVisualizarPreenchimentoFormulario(newValue?.sigla === "F");
		setVisualizarTermoSemAssinatura(newValue?.sigla === "D");
	}

	return (
		<>
			{isLoadingIndex &&
				<SpinnerComponent/>
			}

			<div className="flex w-full flex-row flex-wrap items-center border-blue-gray-50 text-center justify-between mb-5">
				<Breadcrumbs>
					<a onClick={() => navigate(-1)} className="opacity-60"> Termos </a>
					<a className="opacity-60"> Adicionar </a>
				</Breadcrumbs>
				<ButtonDefaultComponent onClick={() => navigate(-1)} description={"Voltar"}/>
			</div>
			<Card className="w-100">
				<CardBody className="flex flex-col gap-4">
					<div className={"grid gap-x-6 gap-y-8 sm:grid-cols-12"}>
						<div className={"sm:col-span-6"}>
							<Autocomplete
								disablePortal
								options={listaRequerimentos}
								getOptionLabel={(option) => `# ${option.id} - ${option.primeiro_nubente} / ${option.segundo_nubente}`}
								value={requerimentoObject}
								size={"small"}
								renderInput={(params) => <TextField {...params} label={"Selecione o Requerimento"}/>}
								onChange={(_, newValue) => setRequerimentoObject(newValue)}
							/>
						</div>

						<div className={"sm:col-span-6"}>
							<Autocomplete
								disablePortal
								options={listaFormasEnvio}
								getOptionLabel={(option) => option.descricao}
								value={formaEnvioObject}
								size={"small"}
								renderInput={(params) => <TextField {...params} label={"Forma de Envio do Termo"}/>}
								onChange={(_, newValue) => handleFormaEnvio(newValue)}
							/>
						</div>
					</div>

					{visualizarPreenchimentoFormulario && requerimentoObject !== null &&
						<FormularioNovoEditar textoCriarAtualizar={"Criar Termo"} novo={true} requerimento={requerimentoObject}/>
					}

					{visualizarTermoSemAssinatura && requerimentoObject !== null &&
						<FormularioSemAssinatura textoCriarAtualizar={"Criar Termo"} novo={true} requerimento={requerimentoObject}/>
					}
				</CardBody>
			</Card>
		</>
	);
}